/**
 * Menu component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

// import _ from "lodash"
// import React from "react"
import { globalHistory } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

// s4n
// import Breadcrumb from 'react-bootstrap/Breadcrumb'



const pageGraphQLIndexesToSearch = [
    'allMenuLinkContentMenuLinkContent', // search in all menu links
    'allNodePage', // search in all page pages
    // 'allNodeArticle', // search in all article pages
    'allNodeBlog', // search in all blog pages
    'allCommerceProductDefault', // search in all drupal commerce products
]

/**
 * @todo: build menus of different types not only "main_menu"
 */
class MenuUtils {
    static getAllItems = (graphQLIndex = null) => {
        // @see: https://stackoverflow.com/questions/47883075/how-to-expose-graphql-field-with-different-name
        const data = useStaticQuery(graphql`
            query {
                allMenuLinkContentMenuLinkContent(
                        sort:{
                            fields: [weight],
                            order: ASC
                        }
                    ) {
                    edges {
                        node {
                            id
                            drupal_id
                            title
                            langcode
                            link {
                                uri
                                url
                                title
                            }
                            enabled
                            expanded
                            changed
                            weight
                            menu_name
                            drupal_parent_menu_item
                        }
                    }
                }

                allNodePage {
                    edges {
                        node {
                            id
                            drupal_id
                            drupal_internal__nid
                            title
                            langcode
                            link: path {
                                url: alias
                                langcode
                            }
                        }
                    }
                }

                allNodeArticle {
                    edges {
                        node {
                            id
                            drupal_id
                            drupal_internal__nid
                            title
                            langcode
                            # link: path {
                            #     url: alias
                            #     langcode
                            # }
                        }
                    }
                }

                allNodeBlog {
                    edges {
                        node {
                            id
                            drupal_id
                            drupal_internal__nid
                            title
                            langcode
                            link: path {
                                url: alias
                                langcode
                            }
                        }
                    }
                }

                allCommerceProductDefault {
                    edges {
                        node {
                            id
                            drupal_id
                            drupal_internal__product_id
                            title
                            langcode
                            link: path {
                                url: alias
                                langcode
                            }
                        }
                    }
                }
            }
        `)

        if (graphQLIndex) {
            let object = {};
            object[graphQLIndex] = data[graphQLIndex];

            return object;
        }

        return data;
    }

    /**
     * This method we use just for building main menu
     * 
     * !!! ONLY MENU ITEMS !!!
     * 
     * @todo: Refactor the loop for performance increase
     */
    static getAllItemsTranslated = (locale) => {
        const data = MenuUtils.getAllItems();

        // console.log(data.allMenuLinkContentMenuLinkContent.edges)

        /**
         * Prepare menu items collection without duplications for one locale
         * because of Drupal translation fallback strategy if translation does not exists!
         */
        let translatedFlatMenu = []
        let oTranslatedFlatMenu = {};
        data.allMenuLinkContentMenuLinkContent.edges.forEach(edge => {
            let namespace = `${edge.node.langcode}___${edge.node.drupal_id}`;
            if (locale === edge.node.langcode && typeof oTranslatedFlatMenu[namespace] === `undefined`) {
                oTranslatedFlatMenu[namespace] = true;
                translatedFlatMenu.push(edge)
            }
        });

        // console.log(translatedFlatMenu)
        
        return translatedFlatMenu;
    }

    // @see: http://oskarhane.com/create-a-nested-array-recursively-in-javascript/
    static getNestedChildren = (arr, parent) => {
        let out = []

        for (let i in arr) {
            if (arr[i].node.enabled && arr[i].node.drupal_parent_menu_item === parent) {

                // var children = getNestedChildren(arr, `menu_link_content:${arr[i].node.drupal_id}`)
                // if (children.length) {
                //     arr[i].children = children
                // }
                // ===
                arr[i].node.children = MenuUtils.getNestedChildren(arr, `menu_link_content:${arr[i].node.drupal_id}`)

                out.push(arr[i])
            }
        }

        return out
    }

    static getUniqueId = () => {
        return Date.now() + Math.random();
    }

    static getCurrentMenuItemFromRoute()
    {
        const path = globalHistory.location.pathname;
        const data = MenuUtils.getAllItems();
        // console.log(path)
        // console.log(data)

        let currentMenuItemFromRoute = null;
        for (let i = 0; i < pageGraphQLIndexesToSearch.length; i++) {
            let graphQLIndex = pageGraphQLIndexesToSearch[i];
            !currentMenuItemFromRoute && data[graphQLIndex].edges && data[graphQLIndex].edges.forEach(({ node }) => {
                if (path === `/${node.langcode}${node.link.url}`) { // console.log(node.link.url) // ex. /portfolio we need to add langcode prefix ex. /en
                    currentMenuItemFromRoute = node;
                }
            })
        }

        // console.log(currentMenuItemFromRoute)
        return currentMenuItemFromRoute;
    }

    /**
     * return:
     * {
     *      pl: {
     *          ...menu item nodes attribute for PL langcode
     *      }
     *      en: {
     *          ...menu item nodes attribute for EN langcode
     *      }
     * }
     */
    static getCurrentMenuItemTranslations = () => {
        const currentMenuItemFromRoute = MenuUtils.getCurrentMenuItemFromRoute()

        if (null === currentMenuItemFromRoute) {
            return null;
        }

        const data = MenuUtils.getAllItems();

        /**
         * Search translation not only in menu items form Drupal but also in all nodes fetched from Drupal!
         * This should match page creation GraphQL queries in gatsby-node.js!
         */
        let menuItemTranslation = {};
        for (let i = 0; i < pageGraphQLIndexesToSearch.length; i++) {
            let graphQLIndex = pageGraphQLIndexesToSearch[i];
            data[graphQLIndex].edges && data[graphQLIndex].edges.forEach(({ node }) => {
                if (currentMenuItemFromRoute.drupal_id === node.drupal_id) {
                    menuItemTranslation[node.langcode] = node;
                }
            })
        }

        return menuItemTranslation;
    }

    static getCurrentMenuItemFromRouteUri()
    {
        const currentMenuItemFromRoute = MenuUtils.getCurrentMenuItemFromRoute();
        return currentMenuItemFromRoute ? currentMenuItemFromRoute.link.uri : null;
    }

    /**
     * 
     * @param {*} uri mixed
     */
    static isCurrentUri(uri = null)
    {
        return Array.isArray(uri) ? uri.includes(MenuUtils.getCurrentMenuItemFromRouteUri()) : uri === MenuUtils.getCurrentMenuItemFromRouteUri();
    }

    /**
     * @todo: Refactor
     * 
     * Right now it takes menu link item or node item!!! 
     * But so far data we request is shared for both
     * 
     * @param {*} nid 
     * @param {*} locale 
     */
    static getItemByNodeId(nid = null, locale)
    {
        const data = MenuUtils.getAllItems();
        // const data = MenuUtils.getAllItemsTranslated(locale); // !!! ONLY MENU ITEMS !!!
        
        let nodeMenuItem = null;
        for (let i = 0; i < pageGraphQLIndexesToSearch.length; i++) {
            let graphQLIndex = pageGraphQLIndexesToSearch[i];
            !nodeMenuItem && data[graphQLIndex].edges.forEach(({ node }) => { // stop iteration when node found
                if (
                    locale === node.langcode && (
                        node.drupal_internal__nid && nid === node.drupal_internal__nid
                        || node.drupal_internal__product_id && nid === node.drupal_internal__product_id
                        || node.link && `entity:node/${nid}` === node.link.uri
                    )
                ) {
                    nodeMenuItem = node;
                }
            })
        }

        return nodeMenuItem;
    }

    /**
     * @todo: Refactor
     * 
     * Right now it takes menu link item or node item!!! 
     * But so far data we request is shared for both
     * 
     * @param {*} nid 
     * @param {*} locale 
     */
    static getNodeIdByItem(nodeMenuItem = null, locale = null)
    {
        locale = locale || nodeMenuItem.langcode;
        const aDataMenuItems = MenuUtils.getAllItemsTranslated(locale); // !!! ONLY MENU ITEMS !!!
        // console.log(aDataMenuItems)

        let nid = null;
        aDataMenuItems.forEach(({ node }) => {
            if (
                !nid 
                && nodeMenuItem.link.uri.includes(`entity:node`) 
                && node.link
                && nodeMenuItem.link.uri === node.link.uri
            ) {
                // console.log(nodeMenuItem)
                // console.log(node)

                nid = node.link.uri.split('/')[1]; // `entity:node/${nid}`
                nid = parseInt(nid);
            }
        });

        return nid;
    }

    static getSubpages = () => {

    }

    static getSubpagesByNodeId = (nid = null, locale = null, exclude = []) => {
        const aSubpages = MenuUtils.getItemByNodeId(nid, locale).children.filter(({ node: nodeMenuItem }) => {
            const nid = MenuUtils.getNodeIdByItem(nodeMenuItem, locale);
    
            // console.log(nid)
    
            return nid && !exclude.includes(nid);
        });

        return aSubpages;
    }

    // recursive to the top
    static getBreadcrumbs = () => {
        // return (
        //     <Breadcrumb>
        //         <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
        //         <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">Library</Breadcrumb.Item>
        //         <Breadcrumb.Item active>Data</Breadcrumb.Item>
        //     </Breadcrumb>
        // )
    }
}

export default MenuUtils