import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { injectIntl } from "gatsby-plugin-intl"
import Helmet from "react-helmet"
import { toggleHighContrastMode } from './../../../../s4n/state/actions/highContrast'
import $ from 'jquery'
import useVersion from './../../../../hooks/useVersion'


export const HIGH_CONTRAST_LINK_ID = `website-main-stylesheet`;
export const HIGH_CONTRAST_FILE_PREFIX = `high-contrast-generated_`;

const ComponentStateless = (props) => {
    const [linkHref, setLinkHref] = useState(null);
    const currentBuildVersion = useVersion();
    const { intl } = props;
    const t = intl.formatMessage;

    /**
     * @see: https://reactjs.org/docs/hooks-effect.html
     * Same as componentDidMount() hook because we pass an empty array [] as second argument,
     * this argument watches for changes in passed arguments
     * In componentDidMount we have access to window object elsewhere not always!!!
     */
    useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = () => {
        const $linkStylesheet = $(`link#website-main-stylesheet`);
        // In development styles are not generated to <link> !!!
        if ($linkStylesheet.length) {
            const linkHrefStandard = $linkStylesheet.attr(`href`).replace(`/`, ``);
            setLinkHref(linkHrefStandard);
        }
    }

    return (
        <React.Fragment>
            {props.isHighContrastMode && linkHref && 
                <Helmet
                    defer={false} // @see: https://www.gatsbyjs.com/plugins/gatsby-plugin-react-helmet/?=#titles-dont-appear-when-opening-in-the-background-while-using-gatsby-plugin-offline
                >
                    <link href={`/high-contrast-generated_${linkHref}`} rel="stylesheet" type="text/css" id="hccss-generated" />
                    {/* <link href={`/high-contrast-custom.css${currentBuildVersion}`} rel="stylesheet" type="text/css" id="hccss-custom" /> */}
                </Helmet>
            }
            <div className="accessibility-high-contrast">
                <a onClick={() => props.toggleHighContrastMode(props.isHighContrastMode)} title={ t({ id: `soft4net_accessibility_high_contrast` }) }>
                    <i className="fas fa-adjust"></i>
                </a>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    isHighContrastMode: state.highContrast.isHighContrastMode,
})

// const mapDispatchToProps = (dispatch, ownProps) => ({
//     toggleHighContrastMode: () => dispatch(toggleHighContrastMode(!ownProps.isHighContrastMode)),
// })
const mapDispatchToProps = dispatch => ({
    toggleHighContrastMode: (isHighContrastMode) => dispatch(toggleHighContrastMode(!isHighContrastMode)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ComponentStateless));